@import '../../variables';
@import '../textTypography';
@import '../responsive';

.container {
  background-color: $purple-light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 64px 16px;

  @include responsive(mobile-large) {
    padding: 64px 24px;
  }

  @include responsive(pc-small) {
    flex-direction: row;
    padding-left: 0px;
  }

  .container__image {
    @include responsive(pc-small) {
      margin-right: 24px;
    }

    img {
      width: 100%;
      border-radius: 0 6px 6px 0;
      box-shadow: 0 10px 10px 0 rgba(8, 24, 35, 0.04), 0 20px 25px 0 rgba(8, 24, 35, 0.1);
    }
  }

  .container__content {
    max-width: 100%;
    min-height: 456px;
    border-radius: 4px;
    background-color: white;
    padding: 24px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-shadow: 0 4px 6px 0 rgba(8, 24, 35, 0.05), 0 10px 15px 0 rgba(8, 24, 35, 0.1);

    @include responsive(mobile-large) {
      background-image: url('/images/person-searching.svg');
      background-position: top right;
      background-repeat: no-repeat;
    }

    @include responsive(pc-small) {
      max-width: 460px;
      margin-top: 0;
    }

    @include responsive(pc) {
      max-width: 564px;
    }

    @include responsive(pc-large) {
      max-width: 764px;
    }

    .content__title {
      color: $text-blue-dark-light;
      margin-bottom: 32px;

      @include textTypography(Inter, 38px, normal, 600, 46px, -1.5px, left);

      @include responsive(mobile-large) {
        max-width: 328px;
      }

      @include responsive(tablet) {
        max-width: 493px;
      }

      @include responsive(pc-small) {
        max-width: 259px;
      }

      @include responsive(pc) {
        max-width: 328px;
      }
    }

    .content__text {
      color: $text-blue-dark-lightest;
      margin-bottom: 40px;

      @include textTypography(Inter, 18px, normal, 400, 26px, -0.3px, left);
    }

    a {
      width: 270px;
    }
  }

  .container__bootom__button {
    margin-top: 24px;
    width: 100%;

    a {
      width: 100%;
    }
  }
}
