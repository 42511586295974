@import '../../variables';
@import '../textTypography';
@import '../responsive';

.container {
  background-color: $purple-light;
  padding: 49px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include responsive(mobile-large) {
    padding: 49px 24px;
  }

  @include responsive(tablet) {
    padding: 80px 24px;
  }

  .containerContent {

    .contentTitle {
      color: $text-blue-dark;
      margin-bottom: 16px;

      @include textTypography(Inter, 38px, normal, 600, 46px, -1.5px, center);

      @include responsive(mobile-large) {
        font-size: 56px;
        line-height: 62px;
        letter-spacing: -2.4px;
      }

      @include responsive(tablet) {

        br {
          display: none;
        }
      }
    }

    .contentText {
      color: $text-blue-dark-lighter;
      margin-bottom: 0;

      @include textTypography(Inter, 18px, normal, 400, 26px, -0.3px, center);
    }
  }

  .carouselControllerContainer{
    width: 70px;
    margin-left: calc(100% - 80px);
  }


  .containerCards {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;

    @include responsive(pc-large){
      justify-content: center;
    }
  }
}
