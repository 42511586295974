@import '../../variables';
@import '../textTypography';
@import '../responsive';

.container {

  .containerImage {
    background-color: $blue-light;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 107px;

    img{
      width: auto;
      height: auto;
    }

    @include responsive(mobile-large) {
      height: 142px;
    }

    @include responsive(tablet) {
      height: 256px;
    }

    @include responsive(pc-large) {
      height: 500px;
    }

    .containerImageMain {
      z-index: 2;
      max-width: 288px;
      margin-bottom: -200px;
      padding: 0 16px;
      filter: drop-shadow(0 10.2763px 20.5526px rgb(8 24 35 / 25%));

      @include responsive(mobile-large) {
        padding: 0 24px;
        margin-bottom: -260px;
        max-width: 528px;
      }

      @include responsive(tablet) {
        max-width: 720px;
      }

      @include responsive(pc-small) {
        max-width: 781px;
      }

      @include responsive(pc) {
        margin-bottom: -150px;
        max-width: 760px;
      }

      @include responsive(pc-large) {
        max-width: 1026px;
        margin-bottom: -260px;
      }
    }

    .patterns {
      z-index: 1;

      img {
        position: absolute;

        &:nth-child(1) {
          left: 0;
          top: 10px;
          width: 40px;

          @include responsive(mobile-large) {
            width: 50px;
            top: 0;
          }

          @include responsive(tablet) {
            width: 90px;
          }

          @include responsive(pc) {
            width: 100px;
          }
        }

        &:nth-child(2) {
          right: 0;
          bottom: 0;
          width: 33.54px;

          @include responsive(mobile-large) {
            width: 50px;
          }

          @include responsive(tablet) {
            width: 70px;
          }

          @include responsive(pc) {
            width: 85px;
          }

          @include responsive(pc-large) {
            bottom: 100px;
          }
        }

        &:nth-child(3) {
          right: 50px;
          bottom: 15px;
          width: 45px;

          @include responsive(mobile-large) {
            bottom: initial;
            right: 90px;
            width: 82px;
            top: 30px;
          }

          @include responsive(pc) {
            right: 187px;
          }

          @include responsive(pc-large) {
            right: 20%;
            top: 100px;
          }
        }
      }
    }
  }

  .bottomShape {
    position: relative;
    overflow: hidden;
    height: 50px;
    background-color: $blue-lighter;

    &::before {
      border-radius: 100%;
      position: absolute;
      background-color: $blue-light;
      right: -200px;
      left: -200px;
      top: -200px;
      content: '';
      bottom: 0;
    }
  }

  .containerContent {
    padding-bottom: 40px;
    background-color: $blue-lighter;

    .contentTitle {
      color: $text-blue-dark-light;
      margin-top: 100px;
      margin-bottom: 26px;

      @include textTypography(Inter, 24px, normal, 600, 32px, 0, center);

      @include responsive(mobile-large) {
        font-size: 38px;
        line-height: 46px;
        letter-spacing: -1.5px;
        margin-top: 190px;
      }

      @include responsive(tablet) {
        margin-top: 200px;
      }

      @include responsive(pc) {
        margin-top: 150px;
      }
    }

    .contentItems {
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;

      .contentItem {
        height: 80px;
        width: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        &::after {
          content: ' ';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 40px;
          background-color: $blue-lightest;
        }

        &:last-child {

          &::after {
            display: none;
          }
        }

        .itemIcon {
          color: $text-blue-darker;
          height: 32px;
        }

        .itemText {
          color: $text-blue-dark-light;
          margin-top: 8px;
          margin-bottom: 0;

          @include textTypography(Inter, 16px, normal, 400, 24px, 0, center);
        }
      }
    }
  }
}
