@import '../../variables';
@import '../textTypography';
@import '../responsive';

$header-height: 64px;

.container {
  background-color: $blue-light;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  flex-direction: column;
  padding-top: $header-height;

  @include responsive(tablet) {
    flex-direction: row;
    height: $header-height + 548px;
  }

  @include responsive(pc-small) {
    height: $header-height + 616px;
  }

  @include responsive(pc) {
    background-size: 463px;
  }

  @include responsive(pc-large) {
    height: $header-height + 631px;
  }

  .containerContent {
    padding: 40px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include responsive(mobile-large) {
      max-width: 441px;
      padding: 40px auto;
    }

    @include responsive(tablet) {
      max-width: 441px;
      padding: initial;
      margin-left: 24px;
      align-items: flex-start;
    }

    @include responsive(pc-small) {
      max-width: 541px;
      margin-left: 24px;
    }

    @include responsive(pc) {
      margin-left: 48px;
    }

    @include responsive(pc-large) {
      margin-left: 154px;
    }

    .contentTitle {
      color: $text-blue-dark;
      margin: 0;

      @include textTypography(Inter, 38px, normal, 600, 46px, -1.5px, center);

      @include responsive(mobile-large) {
        font-size: 56px;
        line-height: 62px;
        letter-spacing: -2.4px;
      }

      @include responsive(tablet) {
        text-align: left;
      }

      @include responsive(pc-small) {
        font-size: 72px;
        line-height: 72px;
        letter-spacing: -3px;
      }
    }

    .contentText {
      color: $text-blue-dark-light;
      margin: 16px 0 24px;

      p{
        margin: 0;
      }

      @include textTypography(Inter, 16px, normal, 400, 24px, 0, center);

      @include responsive(mobile-large) {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.3px;
      }

      @include responsive(tablet) {
        text-align: left;
      }

      @include responsive(pc-small) {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.4px;
        margin-bottom: 40px;
        margin-top: 24px;
      }
    }
  }

  .containerImage {
    margin-bottom: 48px;

    img{
      width: 100%;
      height: auto;
    }


    @include responsive(tablet) {
      margin-bottom: 0;
    }

    @include responsive(pc-small) {
      max-width: 381px;
    }

    @include responsive(pc) {
      max-width: 463px;
    }

    @include responsive(pc-large) {
      max-width: 474px;
    }

  }
}
