@import '../../variables';
@import '../textTypography';
@import '../responsive';

.container {
  background-color: $blue-lighter;
  padding: 48px 16px 80px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @include responsive(mobile-large) {
    padding: 48px 24px 80px 24px;
  }

  @include responsive(tablet) {
    flex-direction: row;
  }

  @include responsive(pc-large) {
    padding: 112px 155px 80px 155px;
  }

  .container__content {
    @include responsive(tablet) {
      max-width: 344px;
      padding-right: 24px;
    }

    @include responsive(pc-small) {
      max-width: 461px;
    }

    @include responsive(pc) {
      max-width: 563px;
    }

    @include responsive(pc-large) {
      max-width: 631px;
    }

    .content__title {
      color: $text-blue-dark-light;
      margin-bottom: 24px;

      @include textTypography(Inter, 38px, normal, 600, 46px, -1.5px, center);

      br {
        display: none;
      }

      @include responsive(tablet) {
        text-align: left;

        br {
          display: block;
        }
      }

      @include responsive(pc-small) {
        font-size: 46px;
        line-height: 54px;
        letter-spacing: -2px;
      }

      @include responsive(pc) {
        font-size: 56px;
        line-height: 62px;
        letter-spacing: -2.4px;
      }
    }

    .content__text {
      color: $text-blue-dark-lightest;
      margin-bottom: 24px;

      @include textTypography(Inter, 16px, normal, 400, 24px, 0, center);

      @include responsive(tablet) {
        text-align: left;
      }

      @include responsive(pc-small) {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.3px;
        text-align: left;
      }
    }

    .content__more {
      padding-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @include responsive(tablet) {
        justify-content: flex-start;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        background-color: $blue-lightest-more;
        height: 1px;
        width: 136px;

        @include responsive(mobile-large) {
          width: 343px;
        }

        @include responsive(tablet) {
          width: 217px;
        }
      }

      .content__more__button {
        width: 48px;
        height: 48px;
        margin-right: 17px;
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: white;
        cursor: pointer;

        @include responsive(mobile-large) {
          margin-right: 50px;
        }

        @include responsive(tablet) {
          margin-right: 17px;
        }

        svg {
          margin-left: 3px;
        }
      }

      .content__more__text {
        p {
          margin: 0;
        }

        .content__more__text___simple {
          color: $text-blue-dark-lightest;

          @include textTypography(Inter, 14px, normal, 400, 20px, -0.4px, left);
        }

        .content__more__text___bold {
          color: $primary;
          cursor: pointer;

          @include textTypography(Inter, 16px, normal, 600, 22px, -0.4px, left);
        }
      }
    }
  }

  .container__grid {
    margin-top: 56px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
    row-gap: 20px;

    @include responsive(mobile-large) {
      column-gap: 22px;
      row-gap: 37px;
    }

    .grid__item {
      &:nth-child(even) {
        margin-top: -32px;
      }

      .grid__item__image {
        border-radius: 4px;
        box-shadow: 0 10px 15px rgba(8, 24, 35, 0.1), 0 4px 6px rgba(8, 24, 35, 0.05);
        width: 100%;
      }
    }
  }
}
